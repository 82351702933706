import { useThemeSettingsGroup } from "@/components/context/theme"
import { Container } from "@/components/styled/content"
import { useEffect, useRef } from "react"

export type TCookieDeclaration = {
  fieldGroupName: "Page_Pagecomponentsgroup_PageComponents_CookieDeclaration"
  visibility?: string
}

export default function CookieDeclaration() {
  const { cookiebotId } = useThemeSettingsGroup()
  const ref = useRef<HTMLDivElement>(null)
  const isInitialized = useRef<boolean>(false)

  useEffect(() => {
    if (isInitialized.current) {
      return
    }

    let script = document.createElement("script")
    let node = ref.current as HTMLDivElement

    script.src = `https://consent.cookiebot.com/${cookiebotId}/cd.js`
    script.setAttribute("id", "CookieDeclaration")
    script.setAttribute("type", "text/javascript")
    script.async = true

    node.appendChild(script)
    isInitialized.current = true
  }, [cookiebotId])

  return (
    <Container>
      <div id="cookieConsentDiv" ref={ref} />
    </Container>
  )
}

export const QUERY = `#graphql
... on Page_Pagecomponentsgroup_PageComponents_CookieDeclaration {
  fieldGroupName
}
`
