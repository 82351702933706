export const QUERY = `#graphql
... on Page_Pagecomponentsgroup_PageComponents_Document {
  fieldGroupName
  contentType
  heading
  background
  visibility
  link {
    target
    title
    url
  }
  listDocuments {
    total
    endCursor
    posts {
      ... on Document {
        id
        title
        details {
          file {
            id
            slug
            mediaType
            mediaItemUrl
            sourceUrl
            altText
            mediaDetails {
              height
              width
            }
          }
        }
        documentCategories {
          nodes {
            slug
            name
            uri
          }
        }
      }
    }
  }
  postCat {
    documents {
      nodes {
        id
        title
        details {
          file {
            id
            slug
            mediaType
            mediaItemUrl
            sourceUrl
            altText
            mediaDetails {
              height
              width
            }
          }
        }
        documentCategories {
          nodes {
            slug
            name
            uri
          }
        }
      }
    }
  }
  postObject {
    ... on Document {
        id
        title
        details {
          file {
            id
            slug
            mediaType
            mediaItemUrl
            sourceUrl
            altText
            mediaDetails {
              height
              width
            }
          }
        }
        documentCategories {
          nodes {
            slug
            name
            uri
          }
        }
      }
  }
}
`
