export const GRAVITY_FORM_QUERY_FIELDS = `#graphql
formId
id
title
description
descriptionPlacement
button {
  text
  type
  imageUrl
}
requireLogin
confirmations {
  isDefault
  message
  pageId
  queryString
  type
  url
}
formFields {
  nodes {
    ... on TextField {
      id
      label
      description
      visibility
      descriptionPlacement
      isRequired
      placeholder
      type
      enableAutocomplete
      autocompleteAttribute
      defaultValue
    }
    ... on NumberField {
      id
      label
      description
      visibility
      descriptionPlacement
      isRequired
      placeholder
      type
      enableAutocomplete
      autocompleteAttribute
      defaultValue
    }
    ... on HiddenField {
      id
      label
      visibility
      isRequired
      type
      defaultValue
    }
    ... on TextAreaField {
      id
      label
      description
      visibility
      descriptionPlacement
      isRequired
      type
      defaultValue
      placeholder
    }
    ... on DateField {
      id
      label
      description
      visibility
      descriptionPlacement
      isRequired
      type
      defaultValue
    }
    ... on RadioField {
      id
      label
      description
      visibility
      descriptionPlacement
      isRequired
      type
      enableOtherChoice
      choices {
        isSelected
        text
        value
      }
    }
    ... on CheckboxField {
      id
      label
      description
      visibility
      descriptionPlacement
      isRequired
      type
      choices {
        isSelected
        text
        value
      }
    }
    ... on SelectField {
      id
      label
      description
      visibility
      descriptionPlacement
      isRequired
      type
      enableAutocomplete
      autocompleteAttribute
      placeholder
      choices {
        isSelected
        text
        value
      }
    }
    ... on EmailField {
      id
      label
      description
      visibility
      descriptionPlacement
      isRequired
      type
      enableAutocomplete
      autocompleteAttribute
      defaultValue
      emailConfirmEnabled
      placeholder
      inputs {
        id
        customLabel
        label
        name
        defaultValue
        autocompleteAttribute
        placeholder
      }
    }
    ...on PhoneField {
      id
      label
      description
      visibility
      descriptionPlacement
      isRequired
      type
      enableAutocomplete
      autocompleteAttribute
      defaultValue
      placeholder
    }
    ... on ConsentField {
      id
      label
      description
      visibility
      descriptionPlacement
      isRequired
      type
      checkboxLabel
    }
  }
}
`
