import Image from "next/image"
import type { ACFImage } from "@/types/wordpress"
import { generateImageSizesProp } from "utils/generateImageSizesProp"
import cx from "classnames"
import React from "react"

type Props = {
  image: ACFImage
  contentPosition?: string
  classname?: string
}

export default function ImageBackground({ image, ...rest }: Props) {
  const figureClassNames = cx("relative overflow-hidden aspect-w-4 aspect-h-3")
  return (
    <figure className={cx(figureClassNames)}>
      <Image
        className={cx("object-cover")}
        {...rest}
        src={image.sourceUrl}
        alt={image.altText}
        layout="fill"
        sizes={generateImageSizesProp("100vw", {
          lg: "50vw"
        })}
      />
    </figure>
  )
}
