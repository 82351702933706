import { Document } from "@/components/cards/documents"
import SectionHeader from "@/components/SectionHeader"
import { Component, Container } from "@/styledComponents/content"
import type { WPDocument } from "@/types/wordpress"
export { QUERY } from "./query"

type TDoc = {
  documents: {
    nodes: WPDocument[]
  }
}

export type TDocuments = {
  fieldGroupName: "Page_Pagecomponentsgroup_PageComponents_Document"
  heading: string
  contentType: "posts_by_cat" | "pick_posts" | "all"
  link: {
    url: string
  }
  listDocuments: {
    total: number
    endCursor: string
    posts: WPDocument[]
  }
  postObject: WPDocument[]
  background: string
  postCat: TDoc[]
  visibility?: string
}

export default function Documents({
  heading,
  contentType,
  listDocuments,
  link,
  postObject,
  background,
  postCat
}: TDocuments) {
  return (
    <Component bgColor={background}>
      <SectionHeader title={heading} link={link} />
      <Container className="grid gap-4 sm:grid-cols-2 sm:gap-8 md:gap-x-7 lg:gap-10 xl:gap-12 lg:grid-cols-4">
        {contentType === "posts_by_cat"
          ? postCat?.map((cat) => {
              return cat?.documents?.nodes?.map((doc) => {
                return <Document key={doc.id} post={doc} />
              })
            })
          : contentType === "pick_posts"
          ? postObject?.map((doc) => {
              return <Document key={doc.id} post={doc} />
            })
          : listDocuments?.posts?.map((doc) => {
              return <Document key={doc.id} post={doc} />
            })}
      </Container>
    </Component>
  )
}
