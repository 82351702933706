export const QUERY = `#graphql
... on Page_Pagecomponentsgroup_PageComponents_List {
  fieldGroupName
  heading
  maxSize
  visibility
  listPostData {
    total
    endCursor
    posts {
      uri
      id
      contentType {
        node {
          name
        }
      }
      ... on NodeWithTitle {
        title
      }
      ... on NodeWithExcerpt {
        excerpt
      }
      ... on NodeWithFeaturedImage {
        featuredImage {
          node {
            sourceUrl
            altText
            mediaDetails {
              height
              width
            }
          }
        }
      }
      ... on Post {
        categories {
          nodes {
            slug
            name
            uri
          }
        }
      }
    }
  }
}
`
