import Image from "next/image"
import { generateImageSizesProp } from "utils/generateImageSizesProp"
import type { ACFImage } from "@/types/wordpress"

type Props = {
  image: ACFImage
  numcols?: number
}

export default function ImageColumn({ image, numcols }: Props) {
  return (
    <figure className="mb-4 xl:mb-5 aspect-w-16 aspect-h-12">
      <Image
        src={image.sourceUrl}
        alt={image.altText}
        objectFit="cover"
        layout="fill"
        sizes={generateImageSizesProp("100vw", {
          md: "50vw",
          lg: numcols === 2 ? "50vw" : numcols === 1 ? "100vw" : "25vw",
          xl: numcols === 1 || numcols === 2 ? "50vw" : "25vw"
        })}
      />
    </figure>
  )
}
