import { SORT_OPTIONS } from "@/lib/config"
import { client } from "@/lib/graphql/client"
import {
  getDocumentContentFragment,
  getFeaturedImageFragment,
  getPostCategoriesFragment,
  getPostExcerptFragment,
  getPostTitleFragment,
  getPostTypeFragment
} from "@/lib/graphql/fragments"
import { wpNameToGraphQLName } from "@/lib/wordpress/helpers"
import { Taxonomies } from "@/types/wordpress"
import { DocumentNode, gql } from "@apollo/client"
import * as React from "react"
import type {
  TFilters,
  TOrderOption,
  TPostData,
  TTaxonomiesData
} from "./types"

type Action =
  | { type: "setFilters"; data?: TFilters }
  | { type: "removeFilter"; taxonomy: string }
  | { type: "setPosts"; data: TPostData }
  | { type: "appendPosts"; data: TPostData }
  | { type: "setSearch"; search: string }
  | { type: "setOrder"; option: TOrderOption }

type Dispatch = (action: Action) => void

type State = {
  postData: TPostData
  taxonomies?: TTaxonomiesData
  filters: TFilters
  search: string
  orderby: TOrderOption
}

type ArchiveProviderProps = {
  children: React.ReactNode
  postData: TPostData
  taxonomies?: TTaxonomiesData
  search?: string
}

type Context = {
  state: State
  dispatch: Dispatch
  getPostsWithTaxQuery: ({ after }: { after?: string }) => DocumentNode
}

const ArchiveStateContext = React.createContext<Context | undefined>(undefined)

function archiveReducer(state: State, action: Action) {
  switch (action.type) {
    case "setFilters": {
      return { ...state, filters: { ...state.filters, ...action.data } }
    }

    case "removeFilter": {
      let newState = { ...state }
      delete newState.filters[action.taxonomy]

      return { ...state, filters: { ...newState.filters } }
    }

    case "setPosts": {
      return {
        ...state,
        postData: {
          ...state.postData,
          posts: action.data.posts,
          total: action.data.total,
          endCursor: action.data.endCursor
        }
      }
    }

    case "setOrder": {
      return {
        ...state,
        orderby: action.option
      }
    }

    case "appendPosts": {
      return {
        ...state,
        postData: {
          ...state.postData,
          posts: [...state.postData.posts, ...action.data.posts],
          endCursor: action.data.endCursor
        }
      }
    }

    case "setSearch": {
      return {
        ...state,
        search: action.search
      }
    }

    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}

let initialLoad = true

function ArchiveProvider({
  children,
  postData,
  taxonomies,
  search
}: ArchiveProviderProps) {
  const [state, dispatch] = React.useReducer(archiveReducer, {
    postData,
    taxonomies,
    filters: {},
    search: search || "",
    orderby: SORT_OPTIONS[0]
  })

  let getPostsWithTaxQuery = React.useCallback(
    ({ after = "" }) => {
      return gql`
        query {
          contentNodes(after: "${after}}" where: {
            contentTypes: ${wpNameToGraphQLName(
              state.postData.postType || "post"
            )},
            orderby: {
              field: ${state.orderby.field}
              order: ${state.orderby.order}
            }
            search: "${state.search}"
            taxQuery: {
              taxArray: [
                ${Object.keys(state.filters).map((taxonomy) => {
                  if (!state.filters[taxonomy]) {
                    return
                  }

                  return `{
                    taxonomy: ${
                      Taxonomies[taxonomy as keyof typeof Taxonomies]
                    },
                    field: SLUG,
                    terms: [${state.filters[taxonomy].map(
                      (term) => `"${term}"`
                    )}]
                  }`
                })}
              ]
            }
          }) {
            nodes {
              uri
              id
              date
              ${getFeaturedImageFragment()}
              ${getPostTypeFragment()}
              ${getPostExcerptFragment()}
              ${getPostTitleFragment()}
              ... on Post {
                ${getPostCategoriesFragment()}
              }
              ... on Document {
                ${getDocumentContentFragment()}
            }
            pageInfo {
              total
              endCursor
            }
          }
        }
      `
    },
    [state.filters, state.search, state.orderby, state.postData.postType]
  )

  React.useEffect(() => {
    if (initialLoad) {
      initialLoad = false
      return
    }

    let getPosts = async () => {
      let query = getPostsWithTaxQuery({})
      let { data } = await client.query({
        query
      })

      dispatch({
        type: "setPosts",
        data: {
          posts: data.contentNodes.nodes,
          total: data.contentNodes.pageInfo.total,
          endCursor: data.contentNodes.pageInfo.endCursor
        }
      })
    }

    getPosts()
  }, [
    state.filters,
    state.postData.postType,
    state.orderby,
    getPostsWithTaxQuery
  ])

  const value = { state, dispatch, getPostsWithTaxQuery }

  return (
    <ArchiveStateContext.Provider value={value}>
      {children}
    </ArchiveStateContext.Provider>
  )
}

function useArchive(): Context {
  const context = React.useContext(ArchiveStateContext)

  if (context === undefined) {
    throw new Error("useArchive must be used within a CountProvider")
  }

  return context
}

export { ArchiveProvider, useArchive }
