import SectionHeader from "@/components/SectionHeader"
import { Component, Container } from "@/components/styled/content"
import type { TACFLink, WPTerm } from "@/types/wordpress"
import AccordionItem from "./accordionItem"

export type TAccordion = {
  fieldGroupName: "Page_Pagecomponentsgroup_PageComponents_Accordion"
  accordionRepeater: TAccordionContent[] | null
  accordionData: TAccordionPost | null
  index: number
  heading: string
  background: string
  link: TACFLink
  visibility?: string
}

export type TAccordionContent = {
  content: string
  title: string
  index: string
  faqCategories: {
    nodes: WPTerm[]
  }
}

type TAccordionPost = {
  posts: TAccordionContent[]
}

export default function Accordion({
  accordionData,
  background,
  link,
  heading
}: TAccordion) {
  const data = accordionData?.posts
  if (!data) {
    return null
  }

  return (
    <Component bgColor={background}>
      <div className="max-w-5xl mx-auto lg:px-4">
        <SectionHeader link={link} title={heading} />
        <Container>
          <ul>
            {data.map((item, i) => {
              item.index = `${item.title}-${i}`
              return <AccordionItem key={i} {...item} />
            })}
          </ul>
        </Container>
      </div>
    </Component>
  )
}

export const QUERY = `#graphql
... on Page_Pagecomponentsgroup_PageComponents_Accordion {
  fieldGroupName
  background
  heading
  visibility
  link {
    target
    title
    url
  }
  accordionData {
    posts {
      ... on Faq {
        faqCategories {
          nodes {
            name
            id
            uri
          }
        }
        title
        content
      }
    }
  }
}
`
