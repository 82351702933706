import { Component, Container, Content } from "@/components/styled/content"
import parse from "html-react-parser"
export type TTextBlock = {
  fieldGroupName: "Page_Pagecomponentsgroup_PageComponents_TextBlock"
  textBlockText: string
  visibility?: string
}

export default function TextBlock({ textBlockText }: TTextBlock) {
  if (!textBlockText) {
    return null
  }
  return (
    <Component>
      <Container narrow>
        <Content>{parse(textBlockText)}</Content>
      </Container>
    </Component>
  )
}

export const QUERY = `#graphql
... on Page_Pagecomponentsgroup_PageComponents_TextBlock {
    fieldGroupName
    textBlockText
    visibility
  }
`
