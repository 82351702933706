import { Post } from "@/components/cards/post"
import { Component, Container } from "@/components/styled/content"
import tailwindConfig from "tailwind.config"
import { useMemo, useRef, useState } from "react"
import styled from "styled-components"
import { A11y, Navigation, Pagination, Scrollbar } from "swiper"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import tw from "twin.macro"
import ChevronLeft from "../../icons/chevronLeft"
import ChevronRight from "../../icons/chevronRight"
import type { TList } from "./types"
export { QUERY } from "./query"

let postTypeCards = {
  post: Post,
  page: Post
}

const SwiperWrapper = styled.div`
  .swiper {
    ${tw`overflow-visible`}
  }
`

let { screens } = tailwindConfig.theme

export default function List(props: TList) {
  let { heading, listPostData, maxSize } = props
  let nextButton = useRef<HTMLButtonElement>(null)
  let prevButton = useRef<HTMLButtonElement>(null)
  let [currentSlidesPerView, setCurrentSlidesPerView] = useState(1)

  const MAX_SLIDE_ID = useMemo(
    () =>
      parseInt(maxSize) > listPostData.posts.length
        ? listPostData.posts.length
        : parseInt(maxSize),
    [listPostData.posts.length, maxSize]
  )

  if (listPostData.posts.length === 0) {
    return null
  }

  return (
    <Component className="overflow-hidden" bgColor={"white"}>
      <Container>
        <div
          className={`flex items-center mb-6 md:mb-10 ${
            heading ? "justify-between" : "justify-end"
          }`}>
          {heading && (
            <h2 className="text-2xl font-bold md:text-3xl xl:text-4xl">
              {heading}
            </h2>
          )}
          {MAX_SLIDE_ID > currentSlidesPerView && currentSlidesPerView > 2 && (
            <div className="flex">
              <button
                aria-label="Föregående slide"
                className="flex items-center justify-center w-12 h-12 mr-4 text-white bg-gray-100 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                ref={prevButton}>
                <ChevronLeft />
              </button>
              <button
                aria-label="Nästa slide"
                className="flex items-center justify-center w-12 h-12 text-white bg-gray-100 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                ref={nextButton}>
                <ChevronRight />
              </button>
            </div>
          )}
        </div>
        <SwiperWrapper>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={16}
            slidesPerView={1.2}
            navigation={{
              prevEl: prevButton.current,
              nextEl: nextButton.current
            }}
            breakpoints={{
              [parseInt(screens.sm, 10)]: {
                slidesPerView: 1.2,
                spaceBetween: 16
              },
              [parseInt(screens.md, 10)]: {
                slidesPerView: 2,
                spaceBetween: 32
              },
              [parseInt(screens.lg, 10)]: {
                slidesPerView: 3,
                spaceBetween: 40
              },
              [parseInt(screens.xl, 10)]: {
                slidesPerView: 4,
                spaceBetween: 48
              }
            }}
            onBreakpoint={(swiper, breakpointsParams) => {
              if (typeof breakpointsParams.slidesPerView === "number") {
                setCurrentSlidesPerView(breakpointsParams.slidesPerView)
              }
            }}>
            {listPostData?.posts?.map((post) => {
              let Card =
                postTypeCards[
                  post.contentType?.node.name as keyof typeof postTypeCards
                ]

              if (!Card) {
                return null
              }
              return (
                <SwiperSlide key={post.id}>
                  <Card post={post as keyof typeof Card} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SwiperWrapper>
      </Container>
    </Component>
  )
}
