import { QUERY as archiveQuery } from "@/pageComponents/archive"
import { QUERY as columnsQuery } from "@/pageComponents/columns"
import { QUERY as cookieDeclarationQuery } from "@/pageComponents/cookieDeclaration"
import { QUERY as heroQuery } from "@/pageComponents/hero"
import { QUERY as textBlockQuery } from "@/pageComponents/textBlock"
import { QUERY as accordionQuery } from "@/pageComponents/accordion"
import { QUERY as listQuery } from "@/pageComponents/list"
import { QUERY as imageAndTextQuery } from "@/pageComponents/imageAndText"
import { QUERY as newsCards } from "@/pageComponents/newsCards"
import { QUERY as documents } from "@/pageComponents/documents"
import { QUERY as rates } from "@/pageComponents/rates"

export function getPageComponentsFragment(objectType: string): string {
  return `#graphql
    pageComponentsGroup {
      pageComponents {
        ${heroQuery}
        ${columnsQuery}
        ${archiveQuery}
        ${cookieDeclarationQuery}
        ${textBlockQuery}
        ${accordionQuery}
        ${listQuery}
        ${imageAndTextQuery}
        ${newsCards}
        ${documents}
        ${rates}
      }
    }
    `.replace(/\.\.\. on Page_/g, `... on ${objectType}_`)
}

export function getPostCategoriesFragment(): string {
  return `#graphql
    categories {
      nodes {
        slug
        name
        uri
      }
    }
  `
}

export function getSeoFragment(): string {
  return `#graphql
    seo {
      fullHead
      breadcrumbs {
        text
        url
      }
    }
  `
}

export function getFeaturedImageFragment(): string {
  return `#graphql
    ... on NodeWithFeaturedImage {
      featuredImage {
        node {
          ${getImageFields()}
        }
      }
    }
  `
}

export function getImageFields(): string {
  return `#graphql
    altText
    sourceUrl
    mediaDetails {
      height
      width
    }
  `
}

export function getPostTitleFragment(): string {
  return `#graphql
    ... on NodeWithTitle {
      title
    }
  `
}

export function getPostTypeFragment(): string {
  return `#graphql
    contentType {
      node {
        name
      }
    }
  `
}

export function getPostExcerptFragment(): string {
  return `#graphql
    ... on NodeWithExcerpt {
      excerpt
    }
  `
}

export function getDocumentContentFragment(): string {
  return `#graphql
    details {
      onlyLoggedIn
      file {
        id
        slug
        mediaType
        mediaItemUrl
        sourceUrl
        altText
        mediaDetails {
          height
          width
        }
      }
    }
    documentCategories {
      nodes {
        slug
        name
        uri
      }
    }
  }
  `
}

export function getThemeSettingsFragment(with404 = false): string {
  let fragment404 = with404
    ? `
    notFoundPage {
      ... on Page {
        databaseId
        ${getPageComponentsFragment("Page")}
        ${getSeoFragment()}
      }
    }
  `
    : ""

  return `#graphql
    themeSettingsPage {
      themeSettingsGroup {
        ${fragment404}
        navTitle
        contact {
          contactTitle
          phone
          email
        }
        postal {
          postalTitle
          postalText
        }
        visit {
          visitTitle
          visitText
        }
        gtmId
        cookiebotId
        stiborPage {
          ... on Page {
            uri
            databaseId
            status
          }
        }
        consentWysiwyg
        loginWysiwyg
        registerSuccessWysiwyg
        forgotPasswordSuccessWysiwyg
      }
    }
  `
}

export function getSiteSettingsFragment(): string {
  return `#graphql
    seo {
      breadcrumbs {
        separator
      }
    }
  `
}

export function getPageInfoFragment(): string {
  return `#graphql
    pageInfo {
      total
      endCursor
    }
  `
}

export function getSeoSettingsFragment(): string {
  return `#graphql
    seo {
      schema {
        siteName
      }
      breadcrumbs {
        searchPrefix
      }
    }
  `
}
