import { Component, Container, Content } from "@/components/styled/content"
import { getImageFields } from "@/lib/graphql/fragments"
import type { ACFImage, TACFLink } from "@/types/wordpress"
import parse from "html-react-parser"
import ImageBackground from "./layouts/image"
import cx from "classnames"
import ACFLink from "@/components/ACFLink"
import Link from "next/link"

export type TImageAndText = {
  fieldGroupName: "Page_Pagecomponentsgroup_PageComponents_ImageAndText"
  title: string
  contentType: "text_image" | "text_box"
  text: string
  boxText: string
  background: string
  image: ACFImage
  button: TACFLink
  contentPosition: string
  className: string
  visibility?: string
}

export default function ImageAndText({
  title,
  text,
  boxText,
  background = "white",
  image,
  button,
  contentPosition,
  contentType
}: TImageAndText) {
  const isTextBox = contentType === "text_box"
  return (
    <Component bgColor={background}>
      <Container
        className={cx("grid", {
          "lg:items-center lg:grid-cols-2 gap-6 lg:gap-10 xl:gap-40":
            !isTextBox,
          "md:items-start md:grid-cols-2 gap-8 md:gap-7 lg:gap-10 xl:gap-12":
            isTextBox
        })}>
        <div
          className={cx({
            "lg:order-last": !isTextBox && contentPosition === "order-last",
            "lg:order-first": !isTextBox && contentPosition === "order-first",
            "order-first": !isTextBox,
            "md:order-last": isTextBox && contentPosition === "order-last",
            "md:order-first": isTextBox && contentPosition === "order-first",
            "order-last border border-gray-border border-t-blue bg-white text-gray-600 p-6 xl:p-10 relative before:absolute before:w-[calc(100%_+_2px)] before:h-2 before:-left-px before:top-0 before:bg-blue":
              isTextBox,
            "!pt-8 xl:!pt-12": isTextBox && image
          })}>
          {image && button && button.url && (
            <Link href={button.url} passHref>
              <a>
                <ImageBackground image={image} />
              </a>
            </Link>
          )}
          {image && !button && <ImageBackground image={image} />}

          {isTextBox && (
            <>
              {boxText && (
                <Content className={cx({ "mt-6": image })}>
                  {parse(boxText)}
                </Content>
              )}
              <ACFLink link={button} className={cx("mt-5 xl:mt-6")} />
            </>
          )}
        </div>
        <div
          className={cx("sm:flex flex-col justify-center items-start", {
            "text-white": background === "blue"
          })}>
          {title && (
            <h2 className="mb-3 xl:mb-4">
              {button && button.url ? (
                <Link href={button.url}>
                  <a className={`!no-underline`}>{title}</a>
                </Link>
              ) : (
                title
              )}
            </h2>
          )}
          {text && <Content>{parse(text)}</Content>}

          {!isTextBox && (
            <ACFLink link={button} className={cx("mt-5 xl:mt-6")} />
          )}
        </div>
      </Container>
    </Component>
  )
}

export const QUERY = `#graphql
... on Page_Pagecomponentsgroup_PageComponents_ImageAndText {
    fieldGroupName
    background
    contentType
    visibility
    button {
        target
        title
        url
    }
    image {
        ${getImageFields()}
    }
    contentPosition
    title
    text
    boxText
  }
`
