import ACFLink from "@/components/ACFLink"
import { Component, Container } from "@/components/styled/content"
import useAuth from "@/lib/hooks/useAuth"
import { StyledInput } from "@/styledComponents/form"
import StyledTable from "@/styledComponents/table"
import type { TACFLink } from "@/types/wordpress"
import { gql, useQuery } from "@apollo/client"
import { getDay, getHours, getMinutes, set, sub } from "date-fns"
import { format, zonedTimeToUtc } from "date-fns-tz"
import "flatpickr/dist/themes/airbnb.css"
import { useState } from "react"
import Flatpickr from "react-flatpickr"
export type TRates = {
  fieldGroupName: "Page_Pagecomponentsgroup_PageComponents_Rates"
  textBlockText: string
  visibility?: string
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { isPublicHoliday, isHoliday } from "swedish-holidays"

export const FETCH_RATES = gql`
  query stibor(
    $action: String! = "fetch_rates"
    $date: String!
    $market: String! = "STIBOR"
  ) {
    fetchRates(action: $action, date: $date, market: $market) {
      status
      message
      data {
        calculation_date
        tenor
        fixing_rate
      }
    }
  }
`

const getlatestRatesDate = () => {
  const currentTime = zonedTimeToUtc(new Date(), "Europe/Stockholm")
  const calc_time = set(currentTime, { hours: 11, minutes: 0 })
  const marketOpenTime = set(new Date(currentTime), {
    hours: getHours(calc_time),
    minutes: getMinutes(calc_time)
  })

  // latestRatesDate is always yesterday at the latest
  let latestRatesDate = sub(new Date(currentTime), { days: 1 })

  // Go back one day if market open time has not yet passed
  if (marketOpenTime > currentTime) {
    latestRatesDate = sub(new Date(latestRatesDate), { days: 1 })
  }

  // Keep going back each day latestRatesDate is a weekend or public holiday
  while (
    getDay(latestRatesDate) === 0 ||
    getDay(latestRatesDate) === 6 ||
    isPublicHoliday(latestRatesDate)
  ) {
    latestRatesDate = sub(new Date(latestRatesDate), { days: 1 })
  }
  return new Date(latestRatesDate)
}

const TableSkeleton = () => {
  return (
    <div className="animate-pulse">
      <div className="bg-gray-300 h-11"></div>
      <div className="bg-gray-100 h-11"></div>
      <div className="bg-gray-200 h-11"></div>
      <div className="bg-gray-100 h-11"></div>
      <div className="bg-gray-200 h-11"></div>
      <div className="bg-gray-100 h-11"></div>
      <div className="bg-gray-200 h-11"></div>
    </div>
  )
}

type Props = {
  ratesLink: TACFLink
}

export default function Rates({ ratesLink }: Props) {
  const latestRatesDate = getlatestRatesDate()
  const latestRatesDateFormated = format(getlatestRatesDate(), "dd-MM-yyyy")
  const todaysDate = new Date()
  const yesterdaysDate = new Date(new Date().setDate(todaysDate.getDate() - 1))
  const yesterdaysDateFormatted = format(yesterdaysDate, "dd-MM-yyyy")
  const { loggedIn, loading: loadingLogin } = useAuth()
  const [selectedDate, setSelectedDate] = useState(latestRatesDate)
  const { loading, error, data } = useQuery(FETCH_RATES, {
    variables: { date: format(selectedDate, "yy-MM-dd") }
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (e: any) => {
    let newDate = new Date(e)
    if (format(new Date(e), "dd-MM-yyyy") === yesterdaysDateFormatted) {
      newDate = getlatestRatesDate()
    }
    setSelectedDate(newDate)
  }

  const tableRows = data?.fetchRates?.data?.map(
    (
      item: { calculation_date: string; fixing_rate: string; tenor: string },
      key: number
    ) => (
      <tr key={key}>
        <td>{item.calculation_date}</td>
        <td>{item.tenor}</td>
        <td>{item.fixing_rate}</td>
      </tr>
    )
  )

  return (
    <Component>
      <Container narrow>
        <header className="grid items-center gap-4 mb-4 sm:grid-cols-2">
          <h2>STIBOR</h2>
          {!loadingLogin && loggedIn ? (
            <>
              <Flatpickr
                render={({ defaultValue }, ref) => {
                  return (
                    <StyledInput
                      defaultValue={defaultValue}
                      ref={ref}
                      className="relative max-w-[150px] place-self-end"
                    />
                  )
                }}
                defaultValue={latestRatesDateFormated}
                onChange={handleOnChange}
                options={{
                  dateFormat: "d-m-Y",
                  minDate: new Date("2020-04-20"),
                  maxDate: latestRatesDateFormated,
                  disable: [
                    (date) => date.getDay() === 0 || date.getDay() === 6,
                    (date) => isPublicHoliday(date), // all red days by law
                    (date) => date.getMonth() === 11 && date.getDate() === 24, // hide christmas
                    (date) => date.getMonth() === 11 && date.getDate() === 31, // hide new years
                    (date) => isHoliday(date).name == "Midsommarafton" // hide midsummer eve
                  ],
                  locale: {
                    firstDayOfWeek: 1
                  }
                }}
              />
              <ACFLink
                link={ratesLink}
                styleType="link"
                className="col-span-2 place-self-start"
              />
            </>
          ) : null}
        </header>
        {loading ? (
          <TableSkeleton />
        ) : (
          <StyledTable className="w-full">
            <thead>
              <tr>
                <th>Calculation Date</th>
                <th>Tenor</th>
                <th>Fixing Rate</th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </StyledTable>
        )}
        {error ||
          (data?.fetchRates?.message && (
            <div className="mt-4">
              {data?.fetchRates?.message || "Could not fetch rates"}
            </div>
          ))}
      </Container>
    </Component>
  )
}

export const QUERY = `#graphql
... on Page_Pagecomponentsgroup_PageComponents_Rates {
    fieldGroupName
    ratesLink {
      target
			title
      url
    }
  }
`
