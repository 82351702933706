import { Button, ButtonUnstyled } from "@/components/buttons"
import { useArchive } from "@/components/pageComponents/archive/context"
import { useEffect, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import type { TFilters, TTaxonomy } from "./types"
import { FocusOn } from "react-focus-on"
import Icon from "@/components/icons/Icon"
import cx from "classnames"

const Checkbox = () => {
  return (
    <>
      <Icon
        name="check"
        className="absolute top-0 right-0 w-6 h-6 opacity-0 peer-checked:opacity-100 focus-visible:outline-black"
      />
      <div className="flex items-center justify-center w-6 h-6 border bg-input-bg border-input-border peer-focus-visible:outline-black peer-focus-visible:outline peer-focus-visible:outline-2 peer-checked:bg-yellow peer-checked:border-yellow"></div>
    </>
  )
}

const Filter = styled.div`
  ${tw`relative border bg-input-bg border-input-border`}

  .bg-gray & {
    ${tw`bg-white`}
  }
`

const FilterDropdown = styled.div`
  ${tw`z-10 flex flex-col lg:border lg:border-t-0 lg:absolute lg:-inset-x-px bg-input-bg lg:border-input-border`}

  .bg-gray & {
    ${tw`bg-white lg:bg-white`}
  }
`

export function Filters() {
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [activeTaxonomy, setActiveTaxonomy] = useState<TTaxonomy>()
  const { state, dispatch } = useArchive()
  const { taxonomies } = state
  const [selectedFilters, setSelectedFilters] = useState<TFilters>({})

  useEffect(() => {
    if (showFilters) {
      setShowFilters(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filters])

  const closeModal = (event: React.FormEvent, taxonomy: string) => {
    event.preventDefault()

    if (selectedFilters[taxonomy]?.length === 0) {
      dispatch({
        type: "removeFilter",
        taxonomy
      })
    } else {
      dispatch({
        type: "setFilters",
        data: { [taxonomy]: selectedFilters[taxonomy] }
      })
    }

    setActiveTaxonomy(undefined)
  }

  const showAllTerms = (taxonomy: string) => {
    let filters = selectedFilters
    filters[taxonomy] = []

    setSelectedFilters(filters)
  }

  const handleTermChange = (
    value: string,
    taxonomy: string,
    checked: boolean
  ) => {
    let filters = selectedFilters

    if (!filters[taxonomy]) {
      filters[taxonomy] = []
    }

    if (checked) {
      filters[taxonomy].push(value)
    } else {
      filters[taxonomy] = filters[taxonomy].filter((term) => term !== value)
    }

    setSelectedFilters(filters)
  }

  return (
    <>
      <Filter>
        <FocusOn
          enabled={showFilters}
          onEscapeKey={() => setShowFilters(false)}
          onClickOutside={() => setShowFilters(false)}>
          <ButtonUnstyled
            id="filter"
            onClick={() => setShowFilters(!showFilters)}
            className="flex items-center justify-between w-full px-4 py-4 text-black placeholder-gray-700/70"
            iconName={"menuArrow"}
            iconClassName={cx("transform-gpu transition-transform text-blue", {
              "rotate-180": showFilters
            })}
            aria-label={showFilters ? "Close filters" : "Show filters"}
            iconPosition="right">
            <span>Filter</span>
          </ButtonUnstyled>
          {showFilters && taxonomies && (
            <FilterDropdown>
              {taxonomies.taxonomies.map((taxonomy) => {
                const count = state.filters[taxonomy.name]?.length
                return (
                  <ButtonUnstyled
                    key={taxonomy.name}
                    name={taxonomy.name}
                    className="flex items-center justify-between p-4"
                    onClick={() => setActiveTaxonomy(taxonomy)}
                    iconName="menuArrow"
                    iconClassName="-rotate-90 text-blue"
                    iconPosition="right">
                    <span>
                      {taxonomy.label}
                      {state.filters[taxonomy.name]?.length && (
                        <span> ({count} selected)</span>
                      )}
                    </span>
                  </ButtonUnstyled>
                )
              })}
            </FilterDropdown>
          )}
        </FocusOn>
      </Filter>
      {activeTaxonomy?.name && (
        <>
          <div
            className="hidden md:block md:fixed md:inset-0 md:bg-black/40 md:z-10"
            role="presentation"
            tabIndex={-1}
          />
          <FocusOn
            className="absolute"
            onEscapeKey={() => setActiveTaxonomy(undefined)}
            onClickOutside={() => setActiveTaxonomy(undefined)}>
            <form className="fixed inset-0 z-20 flex flex-col py-10 overflow-y-auto bg-white lg:right-0 md:max-w-sm md:w-full md:left-auto">
              <span className="px-10 text-lg font-bold">
                {activeTaxonomy.label}
              </span>
              <div className="px-10 my-8 overflow-y-auto">
                <div className="flex flex-col space-y-8">
                  <label
                    className="relative flex justify-between cursor-pointer"
                    htmlFor="showAll">
                    <input
                      className="sr-only peer"
                      type="checkbox"
                      name="showAll"
                      onChange={() => {
                        showAllTerms(String(activeTaxonomy?.name))
                      }}
                      id="showAll"
                      defaultChecked={
                        selectedFilters[activeTaxonomy?.name]?.length === 0
                      }
                    />
                    Show all
                    <Checkbox />
                  </label>
                  {activeTaxonomy.terms.map((term) => {
                    let isActive = () => {
                      return selectedFilters[
                        String(activeTaxonomy?.name)
                      ]?.includes(term.slug)
                    }
                    return (
                      <label
                        className="relative flex justify-between cursor-pointer"
                        key={term.slug}
                        htmlFor={term.slug}>
                        <input
                          className="sr-only peer"
                          type="checkbox"
                          name={activeTaxonomy?.name}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleTermChange(
                              term.slug,
                              String(activeTaxonomy?.name),
                              event.target.checked
                            )
                          }}
                          id={term.slug}
                          value={term.slug}
                          defaultChecked={isActive()}
                        />
                        {term.name}
                        <Checkbox />
                      </label>
                    )
                  })}
                </div>
              </div>
              <Button
                type="submit"
                className="justify-center mx-10 mt-auto"
                onClick={(event) =>
                  closeModal(event, String(activeTaxonomy?.name))
                }>
                Save
              </Button>
            </form>
          </FocusOn>
        </>
      )}
    </>
  )
}
