import { Button } from "@/components/buttons"
import { Post } from "@/components/cards/post"
import { Document } from "@/components/cards/documents"
import {
  ArchiveProvider,
  useArchive
} from "@/components/pageComponents/archive/context"
import { Component, Container } from "@/components/styled/content"
import { client } from "@/lib/graphql/client"
import {
  getDocumentContentFragment,
  getFeaturedImageFragment,
  getPostCategoriesFragment,
  getPostExcerptFragment,
  getPostTitleFragment,
  getPostTypeFragment
} from "@/lib/graphql/fragments"
import { ComponentFooter } from "../footer"
import { ComponentHeader } from "../header"
import { Filters } from "./filters"
import { OrderBy } from "./orderBy"
import { Search } from "./search"
import type { TArchive } from "./types"

let postTypeCards = {
  post: Post,
  page: Post,
  document: Document
}

export default function ArchiveWrapper(props: TArchive) {
  const { footerContent, headerContent, background } = props

  return (
    <Component bgColor={background}>
      {headerContent && <ComponentHeader content={headerContent} />}

      <ArchiveProvider
        postData={{ ...props.postData, postType: props.postType }}
        taxonomies={props.filters}
        search={props.search}>
        <Container>
          <div className="grid grid-cols-1 gap-x-10 gap-y-4 lg:grid-cols-2">
            {props.filters && <Filters />}
            <Search />
          </div>
        </Container>

        <OrderBy />

        <Archive />
      </ArchiveProvider>

      {footerContent && <ComponentFooter content={footerContent} />}
    </Component>
  )
}

function Archive() {
  let {
    state: { postData },
    dispatch,
    getPostsWithTaxQuery
  } = useArchive()

  let fetchNextPage = async () => {
    if (!postData.endCursor) return

    let query = getPostsWithTaxQuery({ after: postData.endCursor })

    let { data } = await client.query({ query })
    let { pageInfo, nodes } = data.contentNodes
    let { endCursor } = pageInfo

    dispatch({ type: "appendPosts", data: { endCursor, posts: nodes } })
  }

  if (postData.total === 0) {
    return (
      <Container>
        <h2 className="mt-12 text-2xl text-center">
          Sorry, your search had no results
        </h2>
      </Container>
    )
  }

  return (
    <Container>
      <span className="block mt-4 md:mt-6 lg:mt-8 h3">
        Showing <strong>{postData.total}</strong> results
      </span>

      <div className="grid grid-cols-1 gap-4 mt-8 sm:gap-8 sm:grid-cols-2 md:gap-x-7 lg:grid-cols-4 lg:gap-x-10 xl:gap-x-12">
        {postData.posts.map((post) => {
          let Card =
            postTypeCards[
              post.contentType?.node.name as keyof typeof postTypeCards
            ]

          if (!Card) {
            return null
          }
          return (
            <Card
              key={post.id}
              className="col-span-1"
              post={post as keyof typeof Card}
            />
          )
        })}
      </div>

      {postData.total && postData.total > postData.posts.length && (
        <div className="flex justify-center mt-8 md:mt-10">
          <Button styleType="light" onClick={fetchNextPage}>
            Load more
          </Button>
        </div>
      )}
    </Container>
  )
}

export const QUERY = `#graphql
... on Page_Pagecomponentsgroup_PageComponents_Archive {
  fieldGroupName
  headerContent
  footerContent
  background
  postType
  visibility
  filters {
    taxonomies {
      name
      label
      terms {
        name
        slug
      }
    }
  }
  postData {
    total
    endCursor
    posts {
      uri
      id
      date
      ${getPostTitleFragment()}
      ${getPostTypeFragment()}
      ${getPostExcerptFragment()}
      ${getFeaturedImageFragment()}
      ... on Post {
        ${getPostCategoriesFragment()}
      }
      ... on Document {
        ${getDocumentContentFragment()}
    }
  }
}
`
