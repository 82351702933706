import type { WPDocument } from "@/types/wordpress"
import Link from "next/link"
import parse from "html-react-parser"
import { DocumentImage } from "../pageComponents/documents/docImage"
import { Terms } from "../post/terms"
import cx from "classnames"
import Icon from "../icons/Icon"
import useAuth from "@/lib/hooks/useAuth"

export type Props = {
  post: WPDocument
}

export function Document({ post }: Props) {
  // hide some documents for non logged in users
  const { loggedIn } = useAuth()
  const onlyLoggedIn = post.details.onlyLoggedIn ? true : false
  if (onlyLoggedIn && !loggedIn) return null

  if (!post) {
    return null
  }

  const docImage = post.details?.file?.mediaType === "image"

  return (
    <article
      className={
        "flex flex-col justify-between col-span-1 p-4 sm:p-6 xl:p-8 bg-white text-gray-600 border border-gray-border"
      }>
      <header>
        {docImage && <DocumentImage image={post.details?.file} />}

        {post.documentCategories?.nodes && (
          <Terms
            className={cx({ "mt-6 xl:mt-9": docImage })}
            terms={post.documentCategories?.nodes}
          />
        )}
        <h4>{post.title && parse(post.title)}</h4>
      </header>

      {post.details?.file?.mediaItemUrl && (
        <footer className="mt-2 text-sm text-blue">
          <Link shallow href={post.details?.file?.mediaItemUrl}>
            <a
              download={post.details?.file?.slug}
              target="_blank"
              className="flex items-center text-base font-semibold underline btn-link underline-offset-2">
              <Icon name="download" className="mr-3" />
              Download
            </a>
          </Link>
        </footer>
      )}
    </article>
  )
}
