import Wysiwyg from "@/components/wysiwyg"
import ImageColumn from "./image"
import type { ACFImage, TACFLink } from "@/types/wordpress"
import parse from "html-react-parser"
import ACFLink from "@/components/ACFLink"

export type TColumnLayoutTextMedia = {
  fieldGroupName: "Page_Pagecomponentsgroup_PageComponents_Columns_Columns_TextMedia"
  wysiwyg: string | null
  image: ACFImage | null
  layout: string
  numcols: number
  columnTitle: string
  columnLink: TACFLink
}

export default function TextMedia({
  numcols,
  layout,
  image,
  wysiwyg,
  columnTitle,
  columnLink
}: TColumnLayoutTextMedia) {
  return (
    <div>
      {layout === "image_text" && image && (
        <ImageColumn numcols={numcols} image={image} />
      )}
      {columnTitle && (
        <h3 className="mb-1 sm:mb-2 xl:mb-3">{parse(columnTitle)}</h3>
      )}
      {wysiwyg && <Wysiwyg wysiwyg={wysiwyg} />}
      {columnLink && (
        <ACFLink
          styleType="link"
          link={columnLink}
          className="mt-2 text-sm break-word sm:mt-3"
          iconClassName="w-3 h-3"
        />
      )}
    </div>
  )
}

export const TEXTMEDIA_LAYOUT_FRAGMENT = `#graphql
  ... on Page_Pagecomponentsgroup_PageComponents_Columns_Columns_TextMedia {
    fieldGroupName
    layout
    columnTitle
    wysiwyg
    image {
      altText
      sourceUrl
      mediaDetails {
        height
        width
      }
    }
    columnLink {
        target
        title
        url
    }
  }
  `
