import Icon from "@/components/icons/Icon"
import { Terms } from "@/components/post/terms"
import { Content } from "@/components/styled/content"
import parse from "html-react-parser"
import styled from "styled-components"
import cx from "classnames"
import tw from "twin.macro"
import type { TAccordionContent } from "."

let Details = styled.details`
  ${tw`relative cursor-pointer select-none`}

  summary {
    ${tw`list-none`}

    &::-webkit-details-marker {
      ${tw`hidden`}
    }

    &::marker {
      ${tw`hidden`}
    }
  }

  p {
    ${tw`select-text xl:text-base`}
  }

  svg {
    ${tw`flex-shrink-0`}
  }

  .minus-sign {
    ${tw`hidden`}
  }

  ul {
    ${tw`px-0 m-0 bg-transparent`}

    li {
      ${tw`relative list-none pl-7`}

      &:before {
        content: "•";
        ${tw`absolute text-lg left-1 text-blue`}
        line-height: 1.3;
      }
    }
  }

  &[open] {
    .plus-sign {
      ${tw`hidden`}
    }

    .minus-sign {
      ${tw`block`}
    }

    &:before {
      ${tw`bg-blue border-t-blue`}
    }

    summary {
      ${tw`!pb-0`}
    }
  }
`

export default function AccordionItem(item: TAccordionContent) {
  const cats = item.faqCategories.nodes
  return (
    <li>
      <Details className="mb-2 md:mb-4 bg-white text-gray-600 border select-none border-gray-border relative before:absolute before:w-[calc(100%_+_2px)] before:h-2 before:-left-px before:top-0">
        <summary
          className="flex flex-wrap items-start justify-between w-full p-4 sm:p-6 lg:p-8"
          aria-controls={`accordion-content-${item.index}`}>
          {cats && <Terms terms={cats} className="w-[calc(100%_-_29px)]" />}
          <div className="flex items-center justify-center w-7 h-7">
            <Icon name="menuArrow" className="plus-sign w-7 h-7 bg-gray" />
            <Icon
              name="menuArrow"
              className="text-white rotate-180 minus-sign w-7 h-7 bg-blue"
            />
          </div>
          <h3 className={cx("question mr-8 font-semibold select-text w-full")}>
            {item.title}
          </h3>
        </summary>
        <Content
          id={`accordion-content-${item.index}`}
          className="!pt-2 p-4 sm:p-6 lg:p-8">
          {item.content && parse(item.content)}
        </Content>
      </Details>
    </li>
  )
}
