import { Component, Container } from "@/components/styled/content"
import { mapTailwindColumns } from "utils/mapTailwindStyles"
import Form, { FORM_LAYOUT_FRAGMENT, TColumnLayoutForm } from "./layouts/form"
import cx from "classnames"
import TextMedia, {
  TColumnLayoutTextMedia,
  TEXTMEDIA_LAYOUT_FRAGMENT
} from "./layouts/textMedia"
import SectionHeader from "@/components/SectionHeader"

export type TColumns = {
  fieldGroupName: "Page_Pagecomponentsgroup_PageComponents_Columns"
  columns: TColumn[]
  heading: string
  background: string
  visibility?: string
  sizes: {
    mobile: string
    tablet: string
    desktop: string
  }
}

export type TColumn = TColumnLayoutTextMedia | TColumnLayoutForm

export default function Columns({
  columns,
  sizes,
  heading,
  background
}: TColumns) {
  if (sizes.tablet > sizes.desktop) {
    sizes.tablet = sizes.desktop
  }

  return (
    <Component bgColor={background}>
      <SectionHeader title={heading} narrow={columns.length < 2} />

      <Container
        narrow={columns.length < 2}
        className={cx(
          "grid md:gap-x-7 gap-8 lg:gap-10 xl:gap-12 grid-cols-1 sm:grid-cols-2",
          mapTailwindColumns(sizes, columns.length),
          {
            "sm:grid-cols-1": columns.length === 1,
            "text-white": "blue" === background
          }
        )}>
        {columns.map((column, index) => {
          switch (column.fieldGroupName) {
            case "Page_Pagecomponentsgroup_PageComponents_Columns_Columns_TextMedia":
              return (
                <TextMedia key={index} {...column} numcols={columns.length} />
              )

            case "Page_Pagecomponentsgroup_PageComponents_Columns_Columns_Form":
              return (
                <Form
                  key={index}
                  {...column}
                  numcols={columns.length}
                  background={background}
                />
              )

            default:
              return (
                // @ts-expect-error column.fieldGroupName could in theory be none of the above during development.
                <NotFound fieldGroupName={column.fieldGroupName} key={index} />
              )
          }
        })}
      </Container>
    </Component>
  )
}

type NotFoundProps = {
  fieldGroupName: string
}

function NotFound({ fieldGroupName }: NotFoundProps) {
  if (process.env.NODE_ENV !== "development") {
    return null
  }

  return (
    <div className="text-red">
      Column layout <em>{fieldGroupName}</em> not found
    </div>
  )
}

export const QUERY = `#graphql
... on Page_Pagecomponentsgroup_PageComponents_Columns {
  fieldGroupName
  background
  heading
  visibility
  sizes {
    mobile
    tablet
    desktop
  }
  columns {
    ${TEXTMEDIA_LAYOUT_FRAGMENT}
    ${FORM_LAYOUT_FRAGMENT}
  }
}
`
