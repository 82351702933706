import type { WPFile } from "@/types/wordpress"
import Image from "next/image"
import { generateImageSizesProp } from "utils/generateImageSizesProp"

type Props = {
  image: WPFile | null
}

export function DocumentImage({ image }: Props) {
  if (!image) {
    return <div className="mb-4 bg-gray-100 aspect-w-4 aspect-h-3" />
  }

  return (
    <figure className="-m-4 sm:-m-6 xl:-m-8 !mb-6 aspect-w-4 aspect-h-4">
      <Image
        src={image.sourceUrl}
        alt={image.altText}
        layout="fill"
        objectFit="cover"
        sizes={generateImageSizesProp("100vw", {
          md: "50vw",
          lg: "25vw"
        })}
      />
    </figure>
  )
}
