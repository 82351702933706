import styled from "styled-components"
import tw from "twin.macro"

const StyledTable = styled.table`
  td,
  th {
    ${tw`p-3 text-sm text-left border border-gray-border`}
  }

  thead > tr {
    ${tw`font-bold text-white bg-blue`}
  }

  tbody tr {
    ${tw`odd:bg-gray-100`}
  }
`

export default StyledTable
