import type { TOrderOption } from "@/components/pageComponents/archive/types"

export const SORT_OPTIONS: TOrderOption[] = [
  {
    field: "DATE",
    order: "DESC",
    label: "Date (newest first)"
  },
  {
    field: "DATE",
    order: "ASC",
    label: "Date (oldest first)"
  },
  {
    field: "TITLE",
    order: "ASC",
    label: "Title (A-Z)"
  },
  {
    field: "TITLE",
    order: "DESC",
    label: "Title (Z-A)"
  }
]

export const TAXONOMY_REWRITE_MAP = {
  "/fastigheter/lagenheter": "city"
}
