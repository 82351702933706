import { SORT_OPTIONS } from "@/lib/config"
import { Container } from "@/styledComponents/content"
import { useEffect, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { useArchive } from "./context"

let initialLoad = true

type StyledPillProps = {
  isActive?: boolean
}

const Pill = styled.button<StyledPillProps>`
  ${tw`relative flex-shrink-0 px-2 py-1 text-sm last:after:w-4 last:after:h-4 last:after:block last:after:absolute last:after:-right-4 bg-gray text-blue`}

  .bg-gray & {
    ${tw`bg-white`}
  }

  &.active {
    ${tw`text-white bg-blue`}
  }
`

export function OrderBy() {
  const [order, setOrder] = useState(SORT_OPTIONS[0])
  const {
    state: { orderby },
    dispatch
  } = useArchive()

  useEffect(() => {
    if (initialLoad) {
      initialLoad = false
      return
    }

    dispatch({ type: "setOrder", option: order })
  }, [order, dispatch])

  return (
    <Container className="pb-3 -mb-3 overflow-x-auto overflow-y-hidden">
      <div className="flex items-center mt-6 space-x-4">
        <span className="flex flex-shrink-0 text-sm font-semibold">
          Sort by:{" "}
        </span>
        {SORT_OPTIONS.map((option, index) => (
          <Pill
            key={index}
            className={
              option.field === orderby.field && option.order === orderby.order
                ? "active"
                : ""
            }
            onClick={() => setOrder(option)}>
            {option.label}
          </Pill>
        ))}
      </div>
    </Container>
  )
}
