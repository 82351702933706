export type WPImage = {
  node: Image
}

export type ACFImage = Image

export type Image = {
  altText: string
  sourceUrl: string
  mediaDetails: {
    height: number
    width: number
  }
}

export type WPTerm = {
  name: string
  slug: string
  uri: string
}

export type WPPost = {
  title: string
  date: Date
  uri: string
  id: string
  excerpt: string
  featuredImage: null | WPImage
  contentType: WPPostType
  categories: {
    nodes: WPTerm[]
  }
}

export type WPFile = {
  id: string
  slug: string
  mediaType: string
  mediaItemUrl: string
  altText: string
  sourceUrl: string
  mediaDetails: {
    height: number
    width: number
  }
}

export type WPDocument = {
  id: string
  title: string
  uri: string
  details: {
    onlyLoggedIn: string
    file: WPFile
  }
  documentCategories: {
    nodes: WPTerm[]
  }
}

export type TACFLink = {
  target?: string
  title?: string
  url?: string
}

export type WPPostType = {
  node: {
    name: string
  }
}

export enum Taxonomies {
  category = "CATEGORY",
  post_tag = "TAG",
  document_category = "DOCUMENT_CATEGORY"
}

export type TVideo = {
  mp4: {
    mediaItemUrl: string
  }
  webm: {
    mediaItemUrl: string
  }
}
