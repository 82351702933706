import SearchIcon from "@/components/icons/search"
import { useArchive } from "@/components/pageComponents/archive/context"
import { useDebounce } from "@/lib/hooks/useDebounce"
import { StyledInput } from "@/styledComponents/form"
import { useRouter } from "next/dist/client/router"
import { useEffect, useState } from "react"

export function Search() {
  let { dispatch, state } = useArchive()
  let [search, setSearch] = useState<string>(state.search)
  let debounceSearch = useDebounce(search, 300)
  let router = useRouter()

  let handleChange = async (event: React.ChangeEvent<HTMLFormElement>) => {
    setSearch(event.target.value)
  }

  let handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
  }

  useEffect(() => {
    dispatch({ type: "setSearch", search: debounceSearch })
  }, [debounceSearch, dispatch])

  useEffect(() => {
    if (router.pathname !== "/sok/[searchTerm]") {
      return
    }

    if (!search) {
      return
    }

    let url = window.location.href
    let urlParts = url.split("/")
    urlParts[urlParts.length - 1] = search
    router.replace(urlParts.join("/"), undefined, { shallow: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <div>
      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
        className="relative">
        <StyledInput
          type="text"
          name="search"
          id="search"
          placeholder="Search"
          defaultValue={search}
          className="py-4"
          aria-label="Search field"
        />
        <SearchIcon className="absolute inset-y-0 my-auto right-4 text-blue"></SearchIcon>
      </form>
    </div>
  )
}
