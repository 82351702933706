import { Container, Content } from "@/components/styled/content"
import parse from "html-react-parser"

type Props = {
  content: string
}

export function ComponentFooter({ content }: Props) {
  return (
    <Container className="mt-10">
      <Content>{parse(content)}</Content>
    </Container>
  )
}
