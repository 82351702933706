import { GRAVITY_FORM_QUERY_FIELDS } from "@/components/gravityForm/queryFields"
import type { TGravityForm } from "@/components/gravityForm/types"
import dynamic from "next/dynamic"

export type TColumnLayoutForm = {
  fieldGroupName: "Page_Pagecomponentsgroup_PageComponents_Columns_Columns_Form"
  form: TGravityForm
  numcols: number
  background?: string
}

const GravityForm = dynamic(() => import("@/components/gravityForm"))

export default function Form({ form, numcols, background }: TColumnLayoutForm) {
  return (
    <div>
      <GravityForm centered={numcols < 2} form={form} background={background} />
    </div>
  )
}

export const FORM_LAYOUT_FRAGMENT = `#graphql
... on Page_Pagecomponentsgroup_PageComponents_Columns_Columns_Form {
  fieldGroupName
  form {
    ${GRAVITY_FORM_QUERY_FIELDS}
  }
}
`
